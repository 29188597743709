// datatables was not loading properly, so we need to run this before linking
// application.js in order to extend jquery with the datatables functions.
// see here: https://datatables.net/forums/discussion/71553/vue3-vite
import $ from 'jquery';
import DataTable from 'datatables.net-bs4';

Object.assign(window, { $ });
new DataTable(window, $); // eslint-disable-line no-new

export default window;
